import React from 'react';
import { PlatformTab, PlatformTabEmptyState } from 'docComponents';

const ReactNativeTab = () => {
  return (
    <PlatformTab>
      <PlatformTabEmptyState platform="reactnative">
        A list of removed color variables are not currently available.
      </PlatformTabEmptyState>
    </PlatformTab>
  );
};

export default ReactNativeTab;

import React from 'react';
import {
  DataTable,
  DataTableRow,
  DataTableHeader,
  DataTableBody,
  DataTableValueBlock,
  DataTableCodeBlock,
  DotSeparator,
  Link,
  Paragraph,
  PlatformTab,
  Section,
  SectionSubhead,
} from 'docComponents';

const WebTab = () => {
  return (
    <PlatformTab>
      <Section title="About Removals">
        <Paragraph>
          Once a variable is removed from the Uniform system, your build will
          break. To fix it, simply update whatever’s missing with a new
          variable. In most cases, you should be able to find a replacement in
          the lists below.
        </Paragraph>

        <Paragraph>
          If there is no replacement, chat with your designer or a member of
          Uniform to figure out next steps.
        </Paragraph>
      </Section>
      <Section>
        <Section title="Button Color">
          <Paragraph>
            Deprecated in:{' '}
            <Link href="https://github.com/hudl/uniform-ui-css/releases/tag/v0.11.11">
              v0.11.11
            </Link>
            <DotSeparator addRightSpace />
            Removed in:{' '}
            <Link href="https://github.com/hudl/uniform-ui-css/releases/tag/v1.0.0">
              v1.0.0
            </Link>
          </Paragraph>

          <Paragraph>
            Find stable variables for buttons{' '}
            <Link href="/resources/code-variables/colors#Buttons">here</Link>.
          </Paragraph>

          <DataTable layout="halves">
            <DataTableHeader>
              <DataTableRow layout="halves">
                <th>Removed Variable</th>
                <th>Stable Variable</th>
              </DataTableRow>
            </DataTableHeader>
            <DataTableBody>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-de-btn-standard-secondary-hover
                </DataTableValueBlock>
                <DataTableCodeBlock>
                  $color-de-btn-standard-secondary-bg-hover
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-de-btn-standard-secondary-active
                </DataTableValueBlock>
                <DataTableCodeBlock>
                  $color-de-btn-standard-secondary-bg-active
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-de-btn-standard-destroy-hover
                </DataTableValueBlock>
                <DataTableCodeBlock>
                  $color-le-btn-standard-destroy-bg-hover
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-de-btn-standard-destroy-active
                </DataTableValueBlock>
                <DataTableCodeBlock>
                  $color-le-btn-standard-destroy-bg-active
                </DataTableCodeBlock>
              </DataTableRow>
            </DataTableBody>
          </DataTable>
        </Section>

        <Section title="Base Brand Color">
          <Paragraph>
            Deprecated in:{' '}
            <Link href="https://github.com/hudl/uniform-ui-css/releases/tag/v0.11.0">
              v0.11.0
            </Link>
            <DotSeparator addRightSpace />
            Removed in:{' '}
            <Link href="https://github.com/hudl/uniform-ui-css/releases/tag/v1.0.0">
              v1.0.0
            </Link>
          </Paragraph>

          <Paragraph>
            Find stable variables for brand{' '}
            <Link href="/resources/code-variables/colors#Brand">here</Link>.
          </Paragraph>
          <DataTable layout="halves">
            <DataTableHeader>
              <DataTableRow layout="halves">
                <th>Removed Variable</th>
                <th>Stable Variable</th>
              </DataTableRow>
            </DataTableHeader>
            <DataTableBody>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$uni-orange</DataTableValueBlock>
                <DataTableCodeBlock>$color-brand-orange</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$uni-electric</DataTableValueBlock>
                <DataTableCodeBlock>$color-brand-electric</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$uni-ink</DataTableValueBlock>
                <DataTableCodeBlock>$color-brand-ink</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock> $uni-evening-tint</DataTableValueBlock>
                <DataTableCodeBlock>No direct mapping</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$uni-evening</DataTableValueBlock>
                <DataTableCodeBlock>$color-brand-evening</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$uni-evening-shade</DataTableValueBlock>
                <DataTableCodeBlock>No direct mapping</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$uni-slate-tint</DataTableValueBlock>
                <DataTableCodeBlock>No direct mapping</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$uni-slate</DataTableValueBlock>
                <DataTableCodeBlock>$color-brand-slate</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$uni-slate-shade</DataTableValueBlock>
                <DataTableCodeBlock>No direct mapping</DataTableCodeBlock>
              </DataTableRow>
            </DataTableBody>
          </DataTable>
        </Section>

        <Section title="Identity Color">
          <Paragraph>
            Deprecated in:{' '}
            <Link href="https://github.com/hudl/uniform-ui-css/releases/tag/v0.11.0">
              v0.11.0
            </Link>
            <DotSeparator addRightSpace />
            Removed in:{' '}
            <Link href="https://github.com/hudl/uniform-ui-css/releases/tag/v1.0.0">
              v1.0.0
            </Link>
          </Paragraph>

          <Paragraph>
            Find stable variables for identity{' '}
            <Link href="/resources/code-variables/colors#Identity">here</Link>.
          </Paragraph>
          <DataTable>
            <DataTableHeader>
              <DataTableRow layout="halves">
                <th>Removed Variable</th>
                <th>Stable Variable</th>
              </DataTableRow>
            </DataTableHeader>
            <DataTableBody>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$identity-maroon</DataTableValueBlock>
                <DataTableCodeBlock>$color-identity-maroon</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$identity-scarlet</DataTableValueBlock>
                <DataTableCodeBlock>$color-identity-scarlet</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$identity-apple</DataTableValueBlock>
                <DataTableCodeBlock>$color-identity-apple</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$identity-orange</DataTableValueBlock>
                <DataTableCodeBlock>$color-identity-orange</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$identity-gold</DataTableValueBlock>
                <DataTableCodeBlock>$color-identity-gold</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$identity-yellow</DataTableValueBlock>
                <DataTableCodeBlock>$color-identity-yellow</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$identity-forest</DataTableValueBlock>
                <DataTableCodeBlock>$color-identity-forest</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$identity-kelly</DataTableValueBlock>
                <DataTableCodeBlock>$color-identity-kelly</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$identity-sky</DataTableValueBlock>
                <DataTableCodeBlock>$color-identity-sky</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$identity-royal</DataTableValueBlock>
                <DataTableCodeBlock>$color-identity-royal</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$identity-navy</DataTableValueBlock>
                <DataTableCodeBlock>$color-identity-navy</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$identity-purple</DataTableValueBlock>
                <DataTableCodeBlock>$color-identity-purple</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$identity-white</DataTableValueBlock>
                <DataTableCodeBlock>$color-identity-white</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$identity-silver</DataTableValueBlock>
                <DataTableCodeBlock>$color-identity-silver</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$identity-grey</DataTableValueBlock>
                <DataTableCodeBlock>$color-identity-grey</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$identity-black</DataTableValueBlock>
                <DataTableCodeBlock>$color-identity-black</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$identity-default</DataTableValueBlock>
                <DataTableCodeBlock>$color-identity-default</DataTableCodeBlock>
              </DataTableRow>
            </DataTableBody>
          </DataTable>
        </Section>

        <Section title="Tagging Color">
          <Paragraph>
            Deprecated in:{' '}
            <Link href="https://github.com/hudl/uniform-ui-css/releases/tag/v0.11.0">
              v0.11.0
            </Link>
            <DotSeparator addRightSpace />
            Removed in:{' '}
            <Link href="https://github.com/hudl/uniform-ui-css/releases/tag/v1.0.0">
              v1.0.0
            </Link>
          </Paragraph>

          <Paragraph>
            Find stable variables for tagging{' '}
            <Link href="/resources/code-variables/colors#Tagging">here</Link>.
          </Paragraph>
          <DataTable>
            <DataTableHeader>
              <DataTableRow layout="halves">
                <th>Removed Variable</th>
                <th>Stable Variable</th>
              </DataTableRow>
            </DataTableHeader>
            <DataTableBody>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$tagging-white</DataTableValueBlock>
                <DataTableCodeBlock>$color-tagging-white</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$tagging-white-shade</DataTableValueBlock>
                <DataTableCodeBlock>
                  $color-tagging-white-shade
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$tagging-grey</DataTableValueBlock>
                <DataTableCodeBlock>$color-tagging-grey</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$tagging-grey-shade</DataTableValueBlock>
                <DataTableCodeBlock>
                  $color-tagging-grey-shade
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$tagging-black</DataTableValueBlock>
                <DataTableCodeBlock>$color-tagging-black</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$tagging-black-shade</DataTableValueBlock>
                <DataTableCodeBlock>
                  $color-tagging-black-shade
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$tagging-yellow</DataTableValueBlock>
                <DataTableCodeBlock>$color-tagging-yellow</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$tagging-yellow-shade</DataTableValueBlock>
                <DataTableCodeBlock>
                  $color-tagging-yellow-shade
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$tagging-orange</DataTableValueBlock>
                <DataTableCodeBlock>$color-tagging-orange</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$tagging-orange-shade</DataTableValueBlock>
                <DataTableCodeBlock>
                  $color-tagging-orange-shade
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$tagging-red</DataTableValueBlock>
                <DataTableCodeBlock>$color-tagging-red</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$tagging-red-shade</DataTableValueBlock>
                <DataTableCodeBlock>
                  $color-tagging-red-shade
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$tagging-green</DataTableValueBlock>
                <DataTableCodeBlock>$color-tagging-green</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$tagging-green-shade</DataTableValueBlock>
                <DataTableCodeBlock>
                  $color-tagging-green-shade
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$tagging-blue</DataTableValueBlock>
                <DataTableCodeBlock>$color-tagging-blue</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$tagging-blue-shade</DataTableValueBlock>
                <DataTableCodeBlock>
                  $color-tagging-blue-shade
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$tagging-purple</DataTableValueBlock>
                <DataTableCodeBlock>$color-tagging-purple</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $tagging-purple-shade{' '}
                </DataTableValueBlock>
                <DataTableCodeBlock>
                  $color-tagging-purple-shade
                </DataTableCodeBlock>
              </DataTableRow>
            </DataTableBody>
          </DataTable>
        </Section>

        <Section title="Utility Color">
          <Paragraph>
            Deprecated in:{' '}
            <Link href="https://github.com/hudl/uniform-ui-css/releases/tag/v0.11.0">
              v0.11.0
            </Link>
            <DotSeparator addRightSpace />
            Removed in:{' '}
            <Link href="https://github.com/hudl/uniform-ui-css/releases/tag/v1.0.0">
              v1.0.0
            </Link>
          </Paragraph>

          <Paragraph>
            Find stable variables for utility{' '}
            <Link href="/resources/code-variables/colors#Utility">here</Link>.
          </Paragraph>
          <DataTable>
            <DataTableHeader>
              <DataTableRow layout="halves">
                <th>Removed Variable</th>
                <th>Stable Variable</th>
              </DataTableRow>
            </DataTableHeader>
            <DataTableBody>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$electric-tint</DataTableValueBlock>
                <DataTableCodeBlock>
                  $color-utility-action-tint
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$electric</DataTableValueBlock>
                <DataTableCodeBlock>$color-utility-action</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$electric-shade</DataTableValueBlock>
                <DataTableCodeBlock>
                  $color-utility-action-shade
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$success-tint</DataTableValueBlock>
                <DataTableCodeBlock>
                  $color-utility-success-tint
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$success</DataTableValueBlock>
                <DataTableCodeBlock>$color-utility-success</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$success-shade</DataTableValueBlock>
                <DataTableCodeBlock>
                  $color-utility-success-shade
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$warning-tint</DataTableValueBlock>
                <DataTableCodeBlock>
                  $color-utility-warning-tint
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$warning</DataTableValueBlock>
                <DataTableCodeBlock>$color-utility-warning</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$warning-shade</DataTableValueBlock>
                <DataTableCodeBlock>
                  $color-utility-warning-shade
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$critical-tint</DataTableValueBlock>
                <DataTableCodeBlock>
                  $color-utility-critical-tint
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$critical</DataTableValueBlock>
                <DataTableCodeBlock>$color-utility-critical</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$critical-shade</DataTableValueBlock>
                <DataTableCodeBlock>
                  $color-utility-critical-shade
                </DataTableCodeBlock>
              </DataTableRow>
            </DataTableBody>
          </DataTable>
        </Section>

        <Section title="Light Environment Color">
          <SectionSubhead>Backgrounds</SectionSubhead>
          <Paragraph>
            Deprecated in:{' '}
            <Link href="https://github.com/hudl/uniform-ui-css/releases/tag/v0.11.0">
              v0.11.0
            </Link>
            <DotSeparator addRightSpace />
            Removed in:{' '}
            <Link href="https://github.com/hudl/uniform-ui-css/releases/tag/v1.0.0">
              v1.0.0
            </Link>
          </Paragraph>

          <Paragraph>
            Find stable variables for backgrounds{' '}
            <Link href="/resources/code-variables/colors#Background">here</Link>
            .
          </Paragraph>
          <DataTable>
            <DataTableHeader>
              <DataTableRow layout="halves">
                <th>Removed Variable</th>
                <th>Stable Variable</th>
              </DataTableRow>
            </DataTableHeader>
            <DataTableBody>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$le-bg-white</DataTableValueBlock>
                <DataTableCodeBlock>$color-le-bg-level0</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$le-bg-white-shade</DataTableValueBlock>
                <DataTableCodeBlock>
                  $color-le-bg-level0-accent
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $le-bg-clean-slate-tint
                </DataTableValueBlock>
                <DataTableCodeBlock>$color-le-bg-level0</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$le-bg-clean-slate</DataTableValueBlock>
                <DataTableCodeBlock>$color-le-bg-level1</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $le-bg-clean-slate-shade
                </DataTableValueBlock>
                <DataTableCodeBlock>
                  $color-le-bg-level1-accent
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $le-bg-bone-slate-tint
                </DataTableValueBlock>
                <DataTableCodeBlock>$color-le-bg-level1</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$le-bg-bone-slate</DataTableValueBlock>
                <DataTableCodeBlock>$color-le-bg-level3</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $le-bg-bone-slate-shade
                </DataTableValueBlock>
                <DataTableCodeBlock>
                  $color-le-bg-level2-accent
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$ui-gray</DataTableValueBlock>
                <DataTableCodeBlock>No direct mapping</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$ui-gray-medium</DataTableValueBlock>
                <DataTableCodeBlock>
                  $color-le-bg-level1-accent
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$ui-gray-light</DataTableValueBlock>
                <DataTableCodeBlock>$color-le-bg-level1</DataTableCodeBlock>
              </DataTableRow>
            </DataTableBody>
          </DataTable>

          <SectionSubhead>Type</SectionSubhead>
          <Paragraph>
            Deprecated in:{' '}
            <Link href="https://github.com/hudl/uniform-ui-css/releases/tag/v0.11.0">
              v0.11.0
            </Link>
            <DotSeparator addRightSpace />
            Removed in:{' '}
            <Link href="https://github.com/hudl/uniform-ui-css/releases/tag/v1.0.0">
              v1.0.0
            </Link>
          </Paragraph>

          <Paragraph>
            Find stable variables for type{' '}
            <Link href="/resources/code-variables/colors#Text">here</Link>.
          </Paragraph>
          <DataTable>
            <DataTableHeader>
              <DataTableRow layout="halves">
                <th>Removed Variable</th>
                <th>Stable Variable</th>
              </DataTableRow>
            </DataTableHeader>
            <DataTableBody>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$le-type-ink</DataTableValueBlock>
                <DataTableCodeBlock>$color-le-text-contrast</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$le-type-typewriter</DataTableValueBlock>
                <DataTableCodeBlock>$color-le-text-default</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$le-type-light-type</DataTableValueBlock>
                <DataTableCodeBlock>$color-le-text-subtle</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$le-type-nonessential</DataTableValueBlock>
                <DataTableCodeBlock>
                  $color-le-text-nonessential
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$le-type-dark</DataTableValueBlock>
                <DataTableCodeBlock>$color-le-text-contrast</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$le-type-medium</DataTableValueBlock>
                <DataTableCodeBlock>$color-le-text-default</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$le-type-light</DataTableValueBlock>
                <DataTableCodeBlock>$color-le-text-subtle</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$ui-text-dark</DataTableValueBlock>
                <DataTableCodeBlock>$color-le-text-contrast</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$ui-text-medium</DataTableValueBlock>
                <DataTableCodeBlock>$color-le-text-default</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$ui-text-light</DataTableValueBlock>
                <DataTableCodeBlock>$color-le-text-subtle</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$ui-text-xlight</DataTableValueBlock>
                <DataTableCodeBlock>
                  $color-le-text-nonessential
                </DataTableCodeBlock>
              </DataTableRow>
            </DataTableBody>
          </DataTable>

          <SectionSubhead>Dividers</SectionSubhead>
          <Paragraph>
            Deprecated in:{' '}
            <Link href="https://github.com/hudl/uniform-ui-css/releases/tag/v0.11.0">
              v0.11.0
            </Link>
            <DotSeparator addRightSpace />
            Removed in:{' '}
            <Link href="https://github.com/hudl/uniform-ui-css/releases/tag/v1.0.0">
              v1.0.0
            </Link>
          </Paragraph>

          <Paragraph>
            Find stable variables for dividers{' '}
            <Link href="/resources/code-variables/colors#Divider">here</Link>.
          </Paragraph>
          <DataTable>
            <DataTableHeader>
              <DataTableRow layout="halves">
                <th>Removed Variable</th>
                <th>Stable Variable</th>
              </DataTableRow>
            </DataTableHeader>
            <DataTableBody>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$le-divider</DataTableValueBlock>
                <DataTableCodeBlock>$color-le-divider</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$le-border</DataTableValueBlock>
                <DataTableCodeBlock>No direct mapping</DataTableCodeBlock>
              </DataTableRow>
            </DataTableBody>
          </DataTable>
        </Section>

        <Section title="Dark Environment Colors">
          <SectionSubhead>Backgrounds</SectionSubhead>
          <Paragraph>
            Deprecated in:{' '}
            <Link href="https://github.com/hudl/uniform-ui-css/releases/tag/v0.11.0">
              v0.11.0
            </Link>
            <DotSeparator addRightSpace />
            Removed in:{' '}
            <Link href="https://github.com/hudl/uniform-ui-css/releases/tag/v1.0.0">
              v1.0.0
            </Link>
          </Paragraph>

          <Paragraph>
            Find stable variables for backgrounds{' '}
            <Link href="/resources/code-variables/colors#Background">here</Link>
            .
          </Paragraph>
          <DataTable>
            <DataTableHeader>
              <DataTableRow layout="halves">
                <th>Removed Variable</th>
                <th>Stable Variable</th>
              </DataTableRow>
            </DataTableHeader>
            <DataTableBody>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $de-bg-pitch-black-tint
                </DataTableValueBlock>
                <DataTableCodeBlock>
                  $color-de-bg-level0-accent
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$de-bg-pitch-black</DataTableValueBlock>
                <DataTableCodeBlock>$color-de-bg-level0</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $de-bg-pitch-black-shade
                </DataTableValueBlock>
                <DataTableCodeBlock>$color-de-bg-level0</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$de-bg-midnight-tint</DataTableValueBlock>
                <DataTableCodeBlock>
                  $color-de-bg-level1-accent
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$de-bg-midnight</DataTableValueBlock>
                <DataTableCodeBlock>$color-de-bg-level1</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$de-bg-midnight-shade</DataTableValueBlock>
                <DataTableCodeBlock>$color-de-bg-level0</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$de-bg-ink-tint</DataTableValueBlock>
                <DataTableCodeBlock>
                  $color-de-bg-level2-accent
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$de-bg-ink</DataTableValueBlock>
                <DataTableCodeBlock>$color-de-bg-level2</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$de-bg-ink-shade</DataTableValueBlock>
                <DataTableCodeBlock>$color-de-bg-level1</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$de-bg-black</DataTableValueBlock>
                <DataTableCodeBlock>$color-de-bg-level0</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$de-bg-black-tint</DataTableValueBlock>
                <DataTableCodeBlock>
                  $color-de-bg-level0-accent
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$de-bg-black-shade</DataTableValueBlock>
                <DataTableCodeBlock>$color-de-bg-level0</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$ui-dark</DataTableValueBlock>
                <DataTableCodeBlock>$color-de-bg-level2</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$ui-dark-medium</DataTableValueBlock>
                <DataTableCodeBlock>
                  $color-de-bg-level2-accent
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$ui-dark-light</DataTableValueBlock>
                <DataTableCodeBlock>
                  $color-de-bg-level3-accent
                </DataTableCodeBlock>
              </DataTableRow>
            </DataTableBody>
          </DataTable>

          <SectionSubhead>Type</SectionSubhead>
          <Paragraph>
            Deprecated in:{' '}
            <Link href="https://github.com/hudl/uniform-ui-css/releases/tag/v0.11.0">
              v0.11.0
            </Link>
            <DotSeparator addRightSpace />
            Removed in:{' '}
            <Link href="https://github.com/hudl/uniform-ui-css/releases/tag/v1.0.0">
              v1.0.0
            </Link>
          </Paragraph>

          <Paragraph>
            Find stable variables for type{' '}
            <Link href="/resources/code-variables/colors#Text">here</Link>.
          </Paragraph>
          <DataTable>
            <DataTableHeader>
              <DataTableRow layout="halves">
                <th>Removed Variable</th>
                <th>Stable Variable</th>
              </DataTableRow>
            </DataTableHeader>
            <DataTableBody>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$de-type-white</DataTableValueBlock>
                <DataTableCodeBlock>$color-de-text-contrast</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$de-type-bright-type</DataTableValueBlock>
                <DataTableCodeBlock>$color-de-text-default</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$de-type-subtle-type</DataTableValueBlock>
                <DataTableCodeBlock>$color-de-text-subtle</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $de-type-nonessential-dark
                </DataTableValueBlock>
                <DataTableCodeBlock>
                  $color-de-text-nonessential
                </DataTableCodeBlock>
              </DataTableRow>
            </DataTableBody>
          </DataTable>

          <SectionSubhead>Dividers</SectionSubhead>
          <Paragraph>
            Deprecated in:{' '}
            <Link href="https://github.com/hudl/uniform-ui-css/releases/tag/v0.11.0">
              v0.11.0
            </Link>
            <DotSeparator addRightSpace />
            Removed in:{' '}
            <Link href="https://github.com/hudl/uniform-ui-css/releases/tag/v1.0.0">
              v1.0.0
            </Link>
          </Paragraph>

          <Paragraph>
            Find stable variables for dividers{' '}
            <Link href="/resources/code-variables/colors#Divider">here</Link>.
          </Paragraph>
          <DataTable>
            <DataTableHeader>
              <DataTableRow layout="halves">
                <th>Removed Variable</th>
                <th>Stable Variable</th>
              </DataTableRow>
            </DataTableHeader>
            <DataTableBody>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$de-divider</DataTableValueBlock>
                <DataTableCodeBlock>$color-de-divider</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$de-border</DataTableValueBlock>
                <DataTableCodeBlock>No direct mapping</DataTableCodeBlock>
              </DataTableRow>
            </DataTableBody>
          </DataTable>
        </Section>
      </Section>
      <Section title="Z-Index">
        <Paragraph>
          Deprecated in:{' '}
          <Link href="https://github.com/hudl/uniform-ui-css/releases/tag/v0.12.0">
            v0.12.0
          </Link>
          <DotSeparator addRightSpace />
          Removed in:{' '}
          <Link href="https://github.com/hudl/uniform-ui-css/releases/tag/v1.0.0">
            v1.0.0
          </Link>
        </Paragraph>

        <Paragraph>
          Find stable variables for z-index and elevation{' '}
          <Link href="/resources/code-variables/zindex-elevation">here</Link>.
        </Paragraph>

        <DataTable>
          <DataTableHeader>
            <DataTableRow layout="halves">
              <th>Removed Variable</th>
              <th>Stable Variable</th>
            </DataTableRow>
          </DataTableHeader>
          <DataTableBody>
            <DataTableRow layout="halves">
              <DataTableValueBlock>$zindex-toast</DataTableValueBlock>
              <DataTableCodeBlock>$elevation-toast</DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="halves">
              <DataTableValueBlock>$zindex-notification</DataTableValueBlock>
              <DataTableValueBlock />
            </DataTableRow>
            <DataTableRow layout="halves">
              <DataTableValueBlock>$zindex-notification-1</DataTableValueBlock>
              <DataTableValueBlock />
            </DataTableRow>
            <DataTableRow layout="halves">
              <DataTableValueBlock>$zindex-notification-2</DataTableValueBlock>
              <DataTableValueBlock />
            </DataTableRow>
            <DataTableRow layout="halves">
              <DataTableValueBlock>$zindex-notification-3</DataTableValueBlock>
              <DataTableValueBlock />
            </DataTableRow>
            <DataTableRow layout="halves">
              <DataTableValueBlock>$zindex-nav</DataTableValueBlock>
              <DataTableCodeBlock>$elevation-nav</DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="halves">
              <DataTableValueBlock>$zindex-nav-1</DataTableValueBlock>
              <DataTableCodeBlock>$elevation-nav1</DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="halves">
              <DataTableValueBlock>$zindex-nav-2</DataTableValueBlock>
              <DataTableCodeBlock>$elevation-nav2</DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="halves">
              <DataTableValueBlock>$zindex-nav-3</DataTableValueBlock>
              <DataTableCodeBlock>$elevation-nav3</DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="halves">
              <DataTableValueBlock>$zindex-modal-overlay</DataTableValueBlock>
              <DataTableCodeBlock>$elevation-modal-scrim</DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="halves">
              <DataTableValueBlock>$zindex-modal</DataTableValueBlock>
              <DataTableCodeBlock>$elevation-modal</DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="halves">
              <DataTableValueBlock>$zindex-modal-1</DataTableValueBlock>
              <DataTableCodeBlock>$elevation-modal1</DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="halves">
              <DataTableValueBlock>$zindex-modal-2</DataTableValueBlock>
              <DataTableCodeBlock>$elevation-modal2</DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="halves">
              <DataTableValueBlock>$zindex-modal-3</DataTableValueBlock>
              <DataTableCodeBlock>$elevation-modal3</DataTableCodeBlock>
            </DataTableRow>
          </DataTableBody>
        </DataTable>
      </Section>
      <Section title="Type Sizes">
        <Paragraph>
          Below are CSS classes that could provide what you’re looking for with
          the given variable. However, the classes go beyond size to offer all
          the necessary specs. If you plan to use the class, you could also
          consider the
          <Link isDesignCodeLink href="/components/type">
            {' '}
            type component
          </Link>
          .
        </Paragraph>
        <DataTable>
          <DataTableHeader>
            <DataTableRow layout="thirds">
              <th>Removed Variable</th>
              <th>Potential replacement</th>
              <th>Alternate option</th>
            </DataTableRow>
          </DataTableHeader>
          <DataTableBody>
            <DataTableRow layout="thirds">
              <DataTableValueBlock>$font-size-1</DataTableValueBlock>
              <DataTableCodeBlock>.uni-text--micro</DataTableCodeBlock>
              <DataTableCodeBlock>.uni-item-title</DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="thirds">
              <DataTableValueBlock>$font-size-2</DataTableValueBlock>
              <DataTableCodeBlock>.uni-text--small</DataTableCodeBlock>
              <DataTableCodeBlock>.uni-subhead--small</DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="thirds">
              <DataTableValueBlock>$font-size-3</DataTableValueBlock>
              <DataTableCodeBlock>.uni-text</DataTableCodeBlock>
              <DataTableCodeBlock>.uni-subhead</DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="thirds">
              <DataTableValueBlock>$font-size-4</DataTableValueBlock>
              <DataTableCodeBlock>No direct mapping</DataTableCodeBlock>
              <DataTableCodeBlock />
            </DataTableRow>
            <DataTableRow layout="thirds">
              <DataTableValueBlock>$font-size-5</DataTableValueBlock>
              <DataTableCodeBlock>No direct mapping</DataTableCodeBlock>
              <DataTableCodeBlock />
            </DataTableRow>
            <DataTableRow layout="thirds">
              <DataTableValueBlock>$font-size-8</DataTableValueBlock>
              <DataTableCodeBlock>No direct mapping</DataTableCodeBlock>
              <DataTableCodeBlock />
            </DataTableRow>
            <DataTableRow layout="thirds">
              <DataTableValueBlock>$body-size-xsmall</DataTableValueBlock>
              <DataTableCodeBlock>.uni-text--micro</DataTableCodeBlock>
              <DataTableCodeBlock>.uni-item-title</DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="thirds">
              <DataTableValueBlock>$body-size-small</DataTableValueBlock>
              <DataTableCodeBlock>.uni-text--small</DataTableCodeBlock>
              <DataTableCodeBlock>.uni-subhead--small</DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="thirds">
              <DataTableValueBlock>$body-size-medium</DataTableValueBlock>
              <DataTableCodeBlock>.uni-text</DataTableCodeBlock>
              <DataTableCodeBlock>.uni-subhead</DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="thirds">
              <DataTableValueBlock>$body-size-large</DataTableValueBlock>
              <DataTableCodeBlock>No direct mapping</DataTableCodeBlock>
              <DataTableCodeBlock />
            </DataTableRow>
            <DataTableRow layout="thirds">
              <DataTableValueBlock>$display-size-h5</DataTableValueBlock>
              <DataTableCodeBlock>No direct mapping</DataTableCodeBlock>
              <DataTableCodeBlock />
            </DataTableRow>
            <DataTableRow layout="thirds">
              <DataTableValueBlock>$display-size-h4</DataTableValueBlock>
              <DataTableCodeBlock>No direct mapping</DataTableCodeBlock>
              <DataTableCodeBlock />
            </DataTableRow>
            <DataTableRow layout="thirds">
              <DataTableValueBlock>$display-size-h3</DataTableValueBlock>
              <DataTableCodeBlock>No direct mapping</DataTableCodeBlock>
              <DataTableCodeBlock />
            </DataTableRow>
            <DataTableRow layout="thirds">
              <DataTableValueBlock>$display-size-h2</DataTableValueBlock>
              <DataTableCodeBlock>.uni-headline--1</DataTableCodeBlock>
              <DataTableCodeBlock />
            </DataTableRow>
            <DataTableRow layout="thirds">
              <DataTableValueBlock>$display-size-h1</DataTableValueBlock>
              <DataTableCodeBlock>No direct mapping</DataTableCodeBlock>
              <DataTableCodeBlock />
            </DataTableRow>
          </DataTableBody>
        </DataTable>
      </Section>

      <Section title="Legacy UI Colors">
        <DataTable>
          <DataTableHeader>
            <DataTableRow layout="halves">
              <th>Removed Variable</th>
              <th>Stable Variable</th>
            </DataTableRow>
          </DataTableHeader>
          <DataTableBody>
            <DataTableRow layout="halves">
              <DataTableValueBlock>$ui-blue</DataTableValueBlock>
              <DataTableCodeBlock>$color-utility-action</DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="halves">
              <DataTableValueBlock>$ui-orange</DataTableValueBlock>
              <DataTableCodeBlock>$color-brand-orange</DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="halves">
              <DataTableValueBlock>$ui-white</DataTableValueBlock>
              <DataTableCodeBlock>$color-base-white</DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="halves">
              <DataTableValueBlock>$ui-black</DataTableValueBlock>
              <DataTableCodeBlock>$color-base-black</DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="halves">
              <DataTableValueBlock>$ui-red</DataTableValueBlock>
              <DataTableCodeBlock>$color-utility-critical</DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="halves">
              <DataTableValueBlock>$ui-red-dark</DataTableValueBlock>
              <DataTableCodeBlock>
                $color-utility-critical-shade
              </DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="halves">
              <DataTableValueBlock>$ui-red-light</DataTableValueBlock>
              <DataTableCodeBlock>
                $color-utility-critical-tint
              </DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="halves">
              <DataTableValueBlock>$ui-yellow</DataTableValueBlock>
              <DataTableCodeBlock>$color-utility-warning</DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="halves">
              <DataTableValueBlock>$ui-yellow-dark</DataTableValueBlock>
              <DataTableCodeBlock>
                $color-utility-warning-shade
              </DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="halves">
              <DataTableValueBlock>$ui-yellow-light</DataTableValueBlock>
              <DataTableCodeBlock>
                $color-utility-warning-tint
              </DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="halves">
              <DataTableValueBlock>$ui-green</DataTableValueBlock>
              <DataTableCodeBlock>$color-utility-success</DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="halves">
              <DataTableValueBlock>$ui-green-dark</DataTableValueBlock>
              <DataTableCodeBlock>
                $color-utility-success-shade
              </DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="halves">
              <DataTableValueBlock>$ui-green-light</DataTableValueBlock>
              <DataTableCodeBlock>
                $color-utility-success-tint
              </DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="halves">
              <DataTableValueBlock>$ui-bg-default</DataTableValueBlock>
              <DataTableCodeBlock>$color-le-bg-level2</DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="halves">
              <DataTableValueBlock>$ui-bg-primary</DataTableValueBlock>
              <DataTableCodeBlock>$color-brand-orange</DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="halves">
              <DataTableValueBlock>$ui-bg-secondary</DataTableValueBlock>
              <DataTableCodeBlock>$color-utility-action</DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="halves">
              <DataTableValueBlock>$ui-bg-tertiary</DataTableValueBlock>
              <DataTableCodeBlock>$color-le-bg-level2</DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="halves">
              <DataTableValueBlock>$ui-bg-white</DataTableValueBlock>
              <DataTableCodeBlock>$color-base-white</DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="halves">
              <DataTableValueBlock>$ui-bg-danger</DataTableValueBlock>
              <DataTableCodeBlock>$color-utility-critical</DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="halves">
              <DataTableValueBlock>$ui-bg-confirm</DataTableValueBlock>
              <DataTableCodeBlock>$color-utility-success</DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="halves">
              <DataTableValueBlock>$ui-dark-100</DataTableValueBlock>
              <DataTableCodeBlock>No direct mapping</DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="halves">
              <DataTableValueBlock>$ui-dark-90</DataTableValueBlock>
              <DataTableCodeBlock>$color-brand-ink</DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="halves">
              <DataTableValueBlock>$ui-dark-80</DataTableValueBlock>
              <DataTableCodeBlock>$color-brand-evening</DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="halves">
              <DataTableValueBlock>$ui-dark-70</DataTableValueBlock>
              <DataTableCodeBlock>No direct mapping</DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="halves">
              <DataTableValueBlock>$ui-dark-60</DataTableValueBlock>
              <DataTableCodeBlock>$color-brand-slate</DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="halves">
              <DataTableValueBlock>$ui-dark-50</DataTableValueBlock>
              <DataTableCodeBlock>No direct mapping</DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="halves">
              <DataTableValueBlock>$ui-dark-40</DataTableValueBlock>
              <DataTableCodeBlock>No direct mapping</DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="halves">
              <DataTableValueBlock>$ui-dark-30</DataTableValueBlock>
              <DataTableCodeBlock>No direct mapping</DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="halves">
              <DataTableValueBlock>$ui-dark-20</DataTableValueBlock>
              <DataTableCodeBlock>No direct mapping</DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="halves">
              <DataTableValueBlock>$ui-dark-10</DataTableValueBlock>
              <DataTableCodeBlock>No direct mapping</DataTableCodeBlock>
            </DataTableRow>

            <DataTableRow>
              <DataTableValueBlock>$ui-gray-100</DataTableValueBlock>
              <DataTableCodeBlock>No direct mapping</DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="halves">
              <DataTableValueBlock>$ui-gray-90</DataTableValueBlock>
              <DataTableCodeBlock>$color-brand-ink</DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="halves">
              <DataTableValueBlock>$ui-gray-80</DataTableValueBlock>
              <DataTableCodeBlock>$color-brand-evening</DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="halves">
              <DataTableValueBlock>$ui-gray-70</DataTableValueBlock>
              <DataTableCodeBlock>No direct mapping</DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="halves">
              <DataTableValueBlock>$ui-gray-60</DataTableValueBlock>
              <DataTableCodeBlock>No direct mapping</DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="halves">
              <DataTableValueBlock>$ui-gray-50</DataTableValueBlock>
              <DataTableCodeBlock>No direct mapping</DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="halves">
              <DataTableValueBlock>$ui-gray-40</DataTableValueBlock>
              <DataTableCodeBlock>No direct mapping</DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="halves">
              <DataTableValueBlock>$ui-gray-30</DataTableValueBlock>
              <DataTableCodeBlock>No direct mapping</DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="halves">
              <DataTableValueBlock>$ui-gray-20</DataTableValueBlock>
              <DataTableCodeBlock>No direct mapping</DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="halves">
              <DataTableValueBlock>$ui-gray-10</DataTableValueBlock>
              <DataTableCodeBlock>No direct mapping</DataTableCodeBlock>
            </DataTableRow>

            <DataTableRow>
              <DataTableValueBlock>$ui-blue-100</DataTableValueBlock>
              <DataTableCodeBlock>No direct mapping</DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="halves">
              <DataTableValueBlock>$ui-blue-90</DataTableValueBlock>
              <DataTableCodeBlock>$color-brand-ink</DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="halves">
              <DataTableValueBlock>$ui-blue-80</DataTableValueBlock>
              <DataTableCodeBlock>$color-brand-evening</DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="halves">
              <DataTableValueBlock>$ui-blue-70</DataTableValueBlock>
              <DataTableCodeBlock>No direct mapping</DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="halves">
              <DataTableValueBlock>$ui-blue-60</DataTableValueBlock>
              <DataTableCodeBlock>No direct mapping</DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="halves">
              <DataTableValueBlock>$ui-blue-50</DataTableValueBlock>
              <DataTableCodeBlock>$color-utility-action</DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="halves">
              <DataTableValueBlock>$ui-blue-40</DataTableValueBlock>
              <DataTableCodeBlock>No direct mapping</DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="halves">
              <DataTableValueBlock>$ui-blue-30</DataTableValueBlock>
              <DataTableCodeBlock>No direct mapping</DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="halves">
              <DataTableValueBlock>$ui-blue-20</DataTableValueBlock>
              <DataTableCodeBlock>No direct mapping</DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="halves">
              <DataTableValueBlock>$ui-blue-10</DataTableValueBlock>
              <DataTableCodeBlock>No direct mapping</DataTableCodeBlock>
            </DataTableRow>
          </DataTableBody>
        </DataTable>
      </Section>
      <Section title="Spacers">
        <Paragraph>
          Deprecated in:{' '}
          <Link href="https://github.com/hudl/uniform-ui-css/releases/tag/v0.10.0">
            v0.10.0
          </Link>
          <DotSeparator addRightSpace />
          Removed in:{' '}
          <Link href="https://github.com/hudl/uniform-ui-css/releases/tag/v1.0.0">
            v1.0.0
          </Link>
        </Paragraph>

        <Paragraph>
          Find stable variables for space{' '}
          <Link href="/resources/code-variables/space">here</Link>.
        </Paragraph>
        <DataTable>
          <DataTableHeader>
            <DataTableRow layout="halves">
              <th>Removed Variable</th>
              <th>Stable Variable</th>
            </DataTableRow>
          </DataTableHeader>
          <DataTableBody>
            <DataTableRow layout="halves">
              <DataTableValueBlock>$spacer-xxsmall</DataTableValueBlock>
              <DataTableCodeBlock>$space-quarter</DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="halves">
              <DataTableValueBlock>$spacer-xsmall</DataTableValueBlock>
              <DataTableCodeBlock>$space-half</DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="halves">
              <DataTableValueBlock>$spacer-small</DataTableValueBlock>
              <DataTableValueBlock>No direct mapping</DataTableValueBlock>
            </DataTableRow>
            <DataTableRow layout="halves">
              <DataTableValueBlock>$spacer-medium</DataTableValueBlock>
              <DataTableCodeBlock>$space-one</DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="halves">
              <DataTableValueBlock>$spacer-large</DataTableValueBlock>
              <DataTableCodeBlock>$space-one-and-half</DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="halves">
              <DataTableValueBlock>$spacer-xlarge</DataTableValueBlock>
              <DataTableCodeBlock>$space-two</DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="halves">
              <DataTableValueBlock>$spacer-xxlarge</DataTableValueBlock>
              <DataTableCodeBlock>$space-three</DataTableCodeBlock>
            </DataTableRow>
          </DataTableBody>
        </DataTable>
      </Section>
    </PlatformTab>
  );
};

export default WebTab;

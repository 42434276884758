import React from 'react';
import { Link, Paragraph, PlatformTab } from 'docComponents';

const AppleTab = () => {
  return (
    <PlatformTab>
      <Paragraph>
        For a list of removed color variables refer to the{' '}
        <Link href="https://github.com/hudl/ios-uniform-ui/pull/28/files">
          Apple color file
        </Link>{' '}
        on github.
      </Paragraph>
    </PlatformTab>
  );
};

export default AppleTab;
